import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import Colours from "react/legacy/parkable-components/styles/Colours";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import Icons from "react/legacy/parkable-components/icon/Icons";
import TableRow from "react/legacy/parkable-components/tableRow/TableRow";
import { getFavouriteParks } from "../../../redux/actions/userOptions";
import _ from "lodash";
import { Routes } from "react/navigation/root/root.paths";
import { Park } from "../../../model/Park";
import Constants from "../../../util/constants";
import BottomSheet, { BottomSheetFlatList, BottomSheetHandleProps, TouchableOpacity } from "@gorhom/bottom-sheet";
import { Role, User } from "../../../model/User";
import { Favourites } from "../../../redux/reducers/userOptions";
import { useAppDispatch, useSelector } from "../../../redux/redux";
import { useNavigation } from "../../../navigation/constants";
import Handle from "./Handle";
import { SharedValue } from "react-native-reanimated";
import { toggleFavouritesViewStateMinimised } from "../../../redux/actions/map";
import { Text } from "react/legacy/parkable-components";

type FavouriteListItem = {
    name: string;
    park: Park;
    iconName: Icons.star | Icons.buildingwork;
};

const generateListItems = (
    user: User | null,
    favouriteParks: Favourites | null
): FavouriteListItem[] => {
    if (favouriteParks == null) {
        return []
    }
    return Object.keys(favouriteParks).map((name) => {
        const park = favouriteParks[name];

        const userIsOrgMember =
            !!park.organisation &&
            (user?.organisations || [])
                .some(role => {
                    return !role.suspended
                        && role.role !== Role.PUBLIC
                        && role.organisation === park.organisation
                });

        const iconName: IconName = userIsOrgMember
            ? Icons.buildingwork
            : Icons.star;

        return {
            name, park, iconName
        }
    })
};

type FavouriteLinksProps = {
    animateOnMount?: boolean;
}

const ROW_HEIGHT = 55;

const FavouriteLinks = (props: FavouriteLinksProps) => {

    const { animateOnMount } = props;
    const { favouriteParks } = useSelector(state => state.userOptions);
    const { employeeSubscriptions } = useSelector(state => state.subscriptions);
    const {
        preferences,
        favouritesViewStateMinimised: minimised,
        enableMapScroll
    } = useSelector(state => state.maps);

    const { user } = useSelector(state => state.user);

    const navigation = useNavigation();
    const dispatch = useAppDispatch();
    const bottomSheetRef = useRef<BottomSheet | null>(null);
    const expandedListItems = generateListItems(user, favouriteParks);
    const nItems = expandedListItems.length;

    const [snapPointIndex, setSnapPointIndex] = useState(0);

    const bottomSheetHeight = useMemo(() => {
        if (nItems === 0) {
            return 20;
        }
        return minimised ? (Platform.OS === "ios" ? 55 : 45) : 115;
    }, [nItems, minimised]);

    const snapPoints = useMemo(() => {
        const maxSize = Math.min(Math.max((expandedListItems.length + 1) * ROW_HEIGHT, 370), 500);

        return [
            bottomSheetHeight, //initial value
            maxSize
        ]
    }, [expandedListItems.length, bottomSheetHeight, user]);

    useEffect(() => {
        bottomSheetRef.current?.snapToIndex(0)
    }, [bottomSheetRef]);

    useEffect(() => {
        dispatch(getFavouriteParks());
    }, []);

    const onPress = (id: number) => {
        bottomSheetRef.current?.snapToIndex(0);
        const employeeSubscription = (_.filter(
            _.values(employeeSubscriptions) || [],
            (sub) =>
                sub.park === id &&
                Constants.Active_Subscription_Status_Array.includes(
                    sub.status
                )
        ) || [])[0];

        if (employeeSubscription) {
            navigation.push(Routes.SingleSubscriptionView, {
                subscriptionId: employeeSubscription.id,
            });
            return
        }

        navigation.push(Routes.ParkDetailView, {
            parkId: id,
            parkingType: preferences.parkingType
        })

    };

    const renderExpandedRow = ({ item }: any) => {
        const { name, park } = item;
        const organisationName = name.substring(0, (name.indexOf(",") !== -1 ? name.indexOf(",") : name.length));
        const carParkandAddressName = park.displayName ? `${park.displayName}, ${park.address}` : `${park.address}`;

        return (
            <TouchableOpacity onPress={() => onPress(item?.park.id)}>
                <TableRow
                    iconLeft={item?.iconName}
                    condensed={true}
                    key={park.id}
                    textProps={{ numberOfLines: 1 }}
                    chevron
                    label={organisationName}
                    labelProps={{ numberOfLines: 1 }}
                >
                    {carParkandAddressName}
                </TableRow>
            </TouchableOpacity>
        );
    };

    function onHandlePress(animatedIndex: SharedValue<number>) {
        if (animatedIndex.value) {
            bottomSheetRef.current?.snapToIndex(animatedIndex.value - 1);
        } else if (enableMapScroll) {
            dispatch(toggleFavouritesViewStateMinimised());
        }
    }

    function handleComponent(props: PropsWithChildren<BottomSheetHandleProps>) {
        return <Handle {...props} onPress={onHandlePress} />
    }

    if (expandedListItems.length === 0) {
        return null
    }

    return (
        <>
            <View style={{ height: bottomSheetHeight, backgroundColor: Colours.NEUTRALS_WHITE }} />
            <BottomSheet
                style={styles.container}
                ref={bottomSheetRef}
                handleComponent={handleComponent}
                snapPoints={snapPoints}
                animateOnMount={animateOnMount}
                onChange={setSnapPointIndex}
            >
                {Platform.OS === "web"
                    ? (
                        <View style={[styles.body, { maxHeight: snapPointIndex === 0 ? undefined : 460 }]}>
                            {expandedListItems.map(item => (
                                <React.Fragment key={item.name}>
                                    {renderExpandedRow({ item })}
                                </React.Fragment>
                            ))}
                        </View>
                    ) : (
                        <BottomSheetFlatList
                            style={styles.body}
                            CellRendererComponent={props => <View style={{ width: "90%", padding: 0, height: ROW_HEIGHT }}>{props.children}</View>}
                            data={expandedListItems}
                            keyExtractor={(i: FavouriteListItem) => i.name}
                            renderItem={renderExpandedRow}
                            contentContainerStyle={styles.contentContainer}
                        />
                    )
                }
            </BottomSheet>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: Colours.GREY_LIGHT,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
    },
    contentContainer: {
        alignItems: "center",
    },
    body: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingHorizontal: 9,
        paddingTop: 10,
        ...Platform.select({
            web: {
                overflow: "scroll"
            }
        })
    },
});

export default FavouriteLinks;
